export const environment = {
  production: false,
  env:'develop',
  // baseUrlAPI: 'http://localhost:5000/dev',
  informUrlAPI:"https://ozjesz2664.execute-api.us-east-1.amazonaws.com/dev",
  awardUrlAPI: "https://wil7p17sg1.execute-api.us-east-1.amazonaws.com/dev",
  baseUrlAPI: 'https://rwb35eimx9.execute-api.us-east-1.amazonaws.com/dev',
  baseWssAPI: 'wss://3nhnr2pz07.execute-api.us-east-1.amazonaws.com/development',//'wss://7ck2h620b7.execute-api.us-east-1.amazonaws.com/dev',
  baseUrlStore: 'store.cbntech.xyz',
  baseUrlDomain: 'cbntech.xyz',
  redirectUriDivemotor: 'http://localhost:4200',
  baseIntegrationUrlDomain: 'api-dev.cbntech.xyz',
  opensearchDomain: "companies_dev",
  googleAnalyticsId: 'G-44HSSRNMY1',
  authCofi: "https://qas-btpcore-6ictvy3v.launchpad.cfapps.us10.hana.ondemand.com/60c5fcb9-8b0a-433d-b98a-dfa4f75411e5.comindraloginportal.comindraloginportal-0.0.1/index.html"
};
